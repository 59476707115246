<template>
  <div class="page-wrapper" style="justify-content: center; align-items: center;">
    <div style="width: 400px;">
      <v-form
        ref="loginForm"
        v-model="valid"
        @submit.prevent="login()"
        action="/"
        method="POST"
      >
        <p class="text-muted">Sign In to your account</p>
        <v-text-field
          label="E-mail"
          v-model="email"
          type="text"
          :rules="[rules.required, rules.validEmail]"
          background-color="white"
          class="tr-input-style"
          color="#EE9D23"
          item-color="orange darken-4"
        ></v-text-field>

        <v-text-field
          label="Password"
          v-model="password"
          id="password"
          background-color="white"
          class="tr-input-style"
          color="#EE9D23"
          item-color="orange darken-4"
          @click:append="show1 = !show1"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          :rules="[rules.required, rules.min]"
        ></v-text-field>

        <div class="my-error-messages-wrapper">
          <p class="pz-errorMessages" v-for="error in errors" :key="error.field">
            {{ error.message }}
          </p>
        </div>
        <v-btn x-large block outlined class="mt-4" type="submit" value="LOGIN">
          LOGIN
        </v-btn>
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      errors: [],
      valid: false,
      email: "",
      password: "",
      show1: false,
      rules: {
        required: (v) => !!v || "Required",
        min: (v) => v.length >= 8 || "Minimum 8 characters",
        validEmail: (v) => /.+@.+/.test(v) || "Email not valid",
      },
    };
  },
  methods: {
    async login() {
      let data = {};
      this.errors = [];
      data.email = this.email.toLowerCase();
      data.password = this.password;
      let formValidated = this.$refs.loginForm.validate();
      if (formValidated) {
        this.$store
          .dispatch("loginUser", {
            email: data.email,
            password: data.password,
          })
          .then((response) => {
            if (response.status) {
              this.$router.push("/").catch(() => {});
            } else {
              this.errors.push({
                field: "User doesn't exist or wrong password.",
                message: "User doesn't exist or wrong password.",
              });
            }
          });
      }
    },
  },
};
</script>
